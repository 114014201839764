@import '1-vars';
@import '2-mixins';


@include md {
  body {
    font-size: 2.4rem;
    line-height: 1.67;
  }
  h1 {
    font-size: 8rem;

    .small {
      font-size: 4.2rem;
    }
  }

  h2 {
    font-size: 4.8rem;
    letter-spacing: 16px;
  }

  h3 {
    font-size: 3.6rem;
    line-height: 1.2;
    letter-spacing: 12px;
  }

  h4 {
    font-size: 4.2rem;
    line-height: 1.14;
  }

  h5 {
    font-size: 2.6rem;
    line-height: 1.3;
    letter-spacing: 8px;
  }
  h6 {
    font-size: 2.6rem;
    line-height: 1.08;
  }
  input {
    height: 52px;
    padding: 15px 24px;
  }
  textarea {
    padding: 15px 24px;
  }

  // main {
  //   section:last-of-type {
  //     margin-bottom: 18rem;
  //   }
  // }
  .nativejs-select {
    &__placeholder {
      height: 52px;
      padding: 15px 24px;
    }
  }
  .divider {
    margin: 4.5rem 0;
  }
  .bg-pattern {
    border-top-width: 20px;
    padding-top: 7rem;
  }
  .bg-pattern--fixed {
    .bg-pattern__overlay {
      height: 654px;
    }
  }
  .bg-pattern--fixed-small {
    padding-top: 11rem;

    .bg-pattern__overlay {
      height: 170px;
    }
  }
  .header-with-subtitle {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6.5rem;
  }

  .has-large-margin-top {
    margin-top: 15rem;
  }

  .has-large-margin-bottom {
    margin-bottom: 15rem;
  }

  .has-medium-margin-top {
    margin-top: 9rem;
  }

  .has-medium-margin-bottom {
    margin-bottom: 9rem;
  }
  .has-medium-font-size {
    font-size: 24px;
  }

  .list--with-links {
    font-size: 2rem;
  }

  .banner {
    font-size: 16px;
    line-height: 1.13;
    padding: 14px 0 12px;

    &__title {
      font-size: 2rem;
      letter-spacing: normal;
      margin-right: 20px;
    }

    &__container {
      display: flex;
      align-items: center;
      background-position: 98% center;
    }
  }

  .discover-list {
    .text-block-with-image:first-child {
      padding-bottom: 22rem;
    }

    .text-block-with-image + .text-block-with-image {
      margin-top: -9rem;
    }
  }
  .text-block-with-image {
    &:last-of-type {
      padding-bottom: 6rem;
    }

    &__image {
      height: 540px;
      max-width: 1080px;
      margin: 0 auto;
    }

    &__content {
      margin-top: -6rem;
    }

    &__inner {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__title {
      padding: 25px 27px 36px 40px;
    }

    .cta {
      min-width: 230px;
    }

    &.text-block-with-image--peach-bg {
      background: linear-gradient(to bottom, #fff 27.5%, #ffddc4 27.5%);
    }
  }
  .text-block-full-image {
    margin-bottom: 8rem;

    &__image {
      height: 720px;
    }

    &__content {
      margin: -6rem auto 0;
      padding: 25px 45px;
    }

    &__title {
      margin-bottom: 3.6rem;
    }

    &__text {
      margin-bottom: 3.6rem;
    }
  }

  .follows {
    padding-bottom: 12.5rem;

    &__info {
      font-size: 1.6rem;
    }
  }

  .follows__slider {
    margin-bottom: 3.6rem;

    .swiper-slide {
      width: 330px;
    }
  }

  .follow-item {
    height: 330px;
  }
  .form {
    .form__field {
      padding-bottom: 4rem;
    }
  }

  .mobile-nav {
    display: none;
  }

  .news {

    & + .navigation {
      padding-top: 0;
    }
  }

  .news-block {
    margin-bottom: 4rem;

    &__title {
      margin-bottom: 6.2rem;
    }

    & + .news-block {
      margin-top: 14.5rem;
    }
  }

  .news-slider {

    .swiper-slide {
      width: 330px;
    }
  }

  .news-item {

    &__image {
      height: 660px;
    }

    &__content {
      padding: 30px 35px;
    }

    &__date {
      font-size: 1.6rem;
    }
  }

  .news-item--podcast {
    .news-item__wrapper {
      height: 330px;
    }

    .news-item__content {
      padding: 0 0 0 36px;
    }

    .news-item__button {
      width: 80px;
      height: 80px;
    }
  }

  .news-item--publication {
    .news-item__image {
      height: 330px;
    }

    .news-item__content {
      padding: 0 0 0 $gap;
    }

    .news-item__button {
      width: 80px;
      height: 80px;
    }
  }
  .socials {
    margin-bottom: 3rem;
  }
  .subscribe {
    padding: 15rem 0 13rem;
  }
  .page-footer {
    border-top-width: 20px;
    padding-bottom: 14.3rem;

    &__logo {
      width: 154px;
      margin-top: -6rem;
      margin-bottom: 2rem;
    }

    &__layout {
      display: flex;
      max-width: 1222px;
      margin: 0 auto;
    }

    &__column {
      flex-basis: 50%;
      padding: 0 38px;
      display: flex;
      flex-direction: column;
    }

    &__sec-nav {
      margin-bottom: 28px;

      ul {
        justify-content: flex-end;
      }
    }

    &__copy {
      text-align: right;
    }

    &__bottom {
      margin-top: auto;
      margin-bottom: 3rem;
    }

    .socials__title {
      margin-bottom: 2rem;
      margin-top: 11px;
    }
  }

  .socials__title {
    font-size: 2rem;
  }

  .activity {
    .text-block-with-image {
      margin-bottom: 0;
      padding-bottom: 6rem;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;

      & > * {
        flex-basis: calc(50% - 44px);
        margin: 0 22px;
      }
    }
  }
  .activity-list-item {
    &__text {
      line-height: 1.4;
      padding-right: 30px;
    }
  }
  .apply-list {
    padding-top: 15rem;
  }
  .apply-item {
    display: flex;
    max-width: 1080px;
    margin: 0 auto 15rem;

    &:last-child {
      margin: 0 auto 0rem;
    }

    &__image {
      flex-basis: 50%;
      height: 750px;
      margin-right: 0;
    }

    &__title {
      font-size: 10rem;
      letter-spacing: 33.33px;
      top: -11.5%;
      right: -30px;
      transform: rotate(-90deg) translate(-10%, -50%);
    }

    &__content {
      flex-basis: 50%;
      margin: 35px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 7rem;
      align-items: flex-start;
    }

    &__text {
      font-size: 24px;
      line-height: 1.67;
      // margin-bottom: 6rem;
    }

    &.apply-item--right-image {
      .apply-item__image {
        margin-left: 0;
        order: 1;
      }

      .apply-item__title {
        transform: rotate(-90deg) translate(-40%, -50%);
        left: 47px;
        top: 52%;
      }

      // .apply-item__content {
      //   padding-right: 7rem;
      //   padding-left: 8rem;
      // }
    }

    &--light-grey {
      background: transparent;

      .apply-item__content {
        background: $light-grey;
      }
    }

    &--peach {
      background: transparent;

      .apply-item__content {
        background: $peach;
      }
    }

    &--navy {
      background: transparent;

      .apply-item__content {
        background: $navy;
      }
    }

    &--light-green {
      background: transparent;

      .apply-item__content {
        background: $light-green;
      }
    }
  }
  .awards {
    &__wrapper {
      .bg-pattern {
        padding-top: 14.5rem;
      }
    }

    &__title {
      line-height: normal;
    }

    &__text {
      margin-bottom: -10rem;
      padding: 0 40px 40px;
      line-height: 1.67;
      font-size: 24px;
    }

    .grant-item {
      padding-bottom: 16px;

      // .link {
      // margin-top: 5rem;
      // }
    }
  }
  .faq {
    margin-bottom: 19.4rem;

    &__header {
      margin-bottom: 4.5rem;
    }

    &__text {
      font-size: 24px;
      line-height: 1.67;
    }
  }
  .featured {

    & + .activity {
      margin-top: 0;
    }

    &__title {
      line-height: normal;
      margin-bottom: 5rem;

      &:before {
        top: 13px;
      }

      &:after {
        bottom: 13px;
      }
    }
  }
  .grants {
    &__title {
      line-height: normal;
    }
  }

  .grant-item {
    padding: 32px 32px 30px;

    &__title {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 24px;
    }
  }
  .learn-item {
    &__image {
      height: 720px;
    }

    &__content {
      max-width: 705px;
      margin: -60px auto 0;
      position: relative;
    }

    &__title {
      //display: inline-block;
      padding: 20px 50px 8px;
    }

    &__inner {
      padding: 20px 30px 0 50px;
    }

    &__text {
      font-size: 24px;
      line-height: 1.67;
    }
  }
  .book-layout {

    &__layout {
      display: flex;
      max-width: 1082px;
      margin: 0 auto;

      & > * {
        flex-basis: 50%;
      }
    }

    &__content {
      padding: 60px 60px 90px;

      // &:before {
      //   opacity: 0.38;
      //   left: 17%;
      // }

      &-title {
        font-size: 26px;
        letter-spacing: 8px;
        line-height: normal;
        margin-bottom: 34px;
      }

      &-text {
        font-size: 24px;
        line-height: 1.67;
      }

      .cta {
        padding: 15px 23px;
      }
    }
  }
  .navigation-pagination {
    font-size: 18px;

    ul {
      justify-content: center;
    }

    .swiper-pagination-bullet {
      padding: 0 20px 10px;
    }
  }
  .navigation {
    padding: 11rem 0 13rem;

    &__slider-nav {
      margin-bottom: 3rem;
      font-size: 18px;
      padding: 0;
    }

    &__nav {
      padding: 0 7px;

      & > span {
        padding: 0 15px 10px;

        &:before {
          width: calc(100% + 14px);
        }
      }
    }

    &__slider {
      .swiper-slide {
        width: 450px;
      }
    }
  }

  .clinic {
    margin-top: 6rem;
    margin-bottom: 4rem;
  }

  .clinic-slider {
    padding: 0 70px;

    .swiper-slide {
      width: 300px;
    }
  }

  .clinic-item {
    &__wrapper {
      padding: 32px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 44px;
    }
  }

  .no-clinic {
    margin: 6rem auto 4.5rem;
    padding: 33px 40px;
  }
  .video-gallery {
    padding-top: 2rem;

    &__title {
      margin-bottom: 4rem;
    }
  }

  .tab-container {
    margin-top: 3rem;
  }
  //TODO: This styles overwrites main styles for activities in tabs
  .tab-pane__inner {
    p:not(.activity-list-item__text) {
      //font-size: 2.4rem;
      //line-height: 1.67;
    }
  }

  .tabs {
    font-size: 18px;
    padding: 0;
  }


  .vertical-tab {
    display: flex;
    max-width: 940px;
    margin: 0 auto;

    .tabs {
      display: block;
      flex-shrink: 0;
      flex-basis: 320px;
      margin: 0;

      .tab {
        padding: 24px 32px 24px 0;
        text-align: right;
        white-space: normal;

        &:before {
          width: 1px;
          height: 100%;
          top: 50%;
          right: 0;
          left: auto;
          transform: translateY(-50%);
        }

        &:after {
          width: 1px;
          top: 50%;
          right: 0;
          left: auto;
          transform: translateY(-50%);
        }

        &.active:before {
          top: 50%;
          right: 0;
          left: auto;
          transform: translateY(-50%);
          width: 2px;
        }

        &:hover:after {
          height: 100%;
          width: 2px;
        }
      }
    }

    .tab-panes {
      flex-basis: 540px;
      padding: 0 32px 0 6rem;
    }

    .tab-pane {
      padding: 0;
    }
  }

  .grid {
    display: flex;
  }

  .grid--2 {
    flex-wrap: wrap;

    .grid__item {
      flex-basis: 50%;
    }
  }

  .grid--3 {
    flex-wrap: wrap;

    .grid__item {
      flex-basis: calc(100% / 3);
    }
  }


  .grid--medium-padding {
    margin-left: -23px;
    margin-right: -23px;

    .grid__item {
      padding: 23px;
    }
  }
  .grid--large-padding {
    justify-content: center;
    flex-direction: row;
    margin-left: -30px;
    margin-right: -30px;

    .grid__item {
      padding: 18px 30px;
      // flex-basis: auto;
    }
  }

  #calendar {
    padding: 40px;

    .fc-header-toolbar {
      .fc-toolbar-title {
        font-size: 42px;
        line-height: 1.14;
      }
    }
  }

  .calendar-popup {
    &__close {
      top: 60px;
      right: 60px;
    }

    &__wrapper {
      padding: 5rem 5rem 6rem;
    }

    &__date {
      font-size: 42px;
      line-height: 1.14;
    }

    &__header-layout {
      margin-bottom: $gap;
    }

    &__body {
      margin-bottom: $gap;
      padding-left: 30px;
    }

    .popup-event {
      &.long {
        margin-left: -53px;
        padding-left: 72px;
      }
    }

    .no-events {
      font-size: 42px;
      line-height: 1.14;
    }
  }

  .calendar-filters {
    justify-content: center;

    li {
      font-size: 18px;
      line-height: 1.56;
      padding: 0 36px 10px;
    }
  }
  .post {
    &__info {
      margin-bottom: 4rem;
    }

    &__slider {
      height: 720px;
      margin-top: 11.5rem;

      .swiper-button-next, .swiper-button-prev {
        left: 60px;
        bottom: 60px;
      }

      .swiper-button-next {
        right: 60px;
        left: auto;
      }
    }

    &__slide {
      .post__info {
        margin-bottom: 3.6rem;
      }
    }
  }
  .post-card {
    &__image {
      height: 500px;
    }
  }

  .preparation {
    h3 {
      margin-bottom: 6rem;
    }
  }

  .quote {

    blockquote {
      font-size: 2.6rem;
      line-height: normal;
      padding: 24px 0;

      &:before,
      &:after {
        font-size: 4.8rem;
        letter-spacing: 16px;
        line-height: 1;
        top: -16px;
        padding-left: 11px;
      }

      &:after {
        bottom: -32px;
        top: auto;
      }
    }
  }

  .related-posts {
    padding: 0 32px;
  }
  .related-posts__slider {
    margin-left: 0;
    padding: 0 6rem;

    .swiper-slide {
      width: 300px;
    }
  }
  .species {
    &__title {
      text-align: center;
      margin-bottom: 2.8rem;
    }
  }

  .species-table-container {
    overflow-x: scroll;
    @include rwd-max(1140) {
      margin-right: -30px;
    }

    table {
      th, td {
        &:first-child {
          padding-left: 60px;
        }
      }
    }
  }

  table {
    font-size: 2.4rem;

    td, th {
      padding: 24px 32px 24px 0;
    }

    th {
      &:first-child {
        padding-left: 28px;
      }

      font-size: 2.4rem;
      line-height: 1.17;
    }

    td {
      &:first-child {
        padding-left: 28px;
      }
    }
  }

  .spec-card {
    width: 300px;

    &__image {
      height: 300px;
    }

    &__title {
      padding: 10px 16px;
      font-size: 2rem;
      letter-spacing: 5px;
    }
  }
  .team-popup {
    &__wrapper {
      padding: 12rem;
    }

    &__close {
      top: 60px;
      right: 60px;
    }
  }

  .team-card--full-width {
    .team-card__layout {
      display: flex;
      margin-bottom: 6rem;
    }

    .team-card__image {
      flex-shrink: 0;
      flex-basis: 43%;
      margin-right: 6rem;
      margin-bottom: 0;
    }

    // .team-card__info,
    // .team-card__email {
    //   margin-bottom: 8px;
    // }
  }
  .volunteer-form {
    position: relative;
    max-width: 1080px;
    margin: -22.5rem auto 0;
    background-color: #fff;
    padding-top: 4rem;

    &__thanks-message {
      padding-top: 57px;
      margin-top: 5rem;
    }
  }
  .accordion {
    details {
      font-size: 2rem;
      line-height: 1.4;

      & > div {
        padding-top: 20px;

        h6 {
          font-size: 2rem;
          line-height: 1.4;
        }
      }
    }

    summary {
      font-size: 2.4rem;
      line-height: 1.08;
    }
  }

  .species-list {
    background-position: 50% 0;
    background-size: 100%;

    &--wildlife {
      background-size: 400px;
      background-position: 50% 30%;
    }

    div {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 42px 0;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        top: 63px;
        display: block;
        width: 100%;
        border-top: 2px dashed $dark-green;
        z-index: -1;
      }
    }

    dt {
      font-size: 26px;
      letter-spacing: 8px;
      line-height: normal;
      padding-right: 16px;
    }

    dd {
      font-size: 24px;
      line-height: 1.14;
      padding-left: 16px;
      background-color: #fff;
    }

    .status {
      align-items: flex-start;
    }
  }

  .species-info {
    padding: 8rem 0;
    border-width: 20px;
  }

  .species-areas {
    display: flex;
    margin: 0 -4rem;

    & > * {
      padding: 0 4rem;
    }

    &__image {
      flex-basis: 52%;
      padding: 0 4rem;
    }

    &__info {
      flex-basis: 48%;
    }

    &__map-list-wrapper {
      width: 65%;
      margin: -30rem 0 5rem 1rem;
      min-height: 240px;
    }

    &__map-list {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .species-slider {
    margin-top: 15rem;
    height: 720px;

    .swiper-button-next, .swiper-button-prev {
      left: 60px;
    }

    .swiper-button-next {
      right: 60px;
      left: auto;
    }
  }
  .has-s-m-margin-top {
    margin-top: 6rem;
  }

  .has-s-m-margin-bottom {
    margin-bottom: 6rem;
  }
}









